import * as React from "react"
import resume from "../../data/profile.json"
import SEO from "../components/seo"
import Header from "../components/header"
import Summary from "../components/summary"
import Experience from "../components/experience"
import Projects from "../components/projects"
import Skills from "../components/skills"
import Education from "../components/education"
import List from "../components/list"
import Footer from "../components/footer"

const IndexPage = () => (
  <main className="antialiased text-neutral-600 bg-neutral-100 min-h-screen sm:p-5">
    <SEO title="Resume" />
    <div className="container mx-auto shadow bg-white py-5 px-10">
      <Header
        contacts={resume.contact}
        name={resume.fullname}
        role={resume.role}
      />
      <Summary data={resume.summary} />
      <div className="border-b border-neutral-300 pb-2 my-5 lg:flex">
        <div className="lg:w-2/3 lg:pr-8">
          {resume.experience && <Experience data={resume.experience} />}
          {resume.projects && <Projects data={resume.projects} />}
        </div>
        <div className="lg:w-1/3 lg:pl-8 lg:border-l lg:border-neutral-300 ">
          {resume.skills && <Skills data={resume.skills} />}
          {resume.education && <Education data={resume.education} />}
          {resume.sidebar &&
            resume.sidebar.map(item => (
              <List key={`${item.title}-side`} data={item} />
            ))}
        </div>
      </div>
      <Footer social={resume.social} />
    </div>
  </main>
)

export default IndexPage
