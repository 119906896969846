import React from "react"

const Projects = ({ data }) => (
  <section>
    <h1 className="section-header">Projects</h1>
    {data.map(item => (
      <article className="mb-1" key={item.name}>
        <h2 className="item-header">{item.name}</h2>
        <h3 className="item-sub">{item.company}</h3>
        <p>{item.description}</p>
        <div className="flex justify-end">
          {item.repo && (
            <a
              className="btn btn-secondary"
              href={item.repo}
              target="_blank"
              rel="noopener noreferrer"
            >
              Visit Repo
            </a>
          )}
          {item.link && (
            <a
              className="btn btn-secondary"
              href={item.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              Visit Project
            </a>
          )}
        </div>
      </article>
    ))}
  </section>
)

export default Projects
