import * as React from "react"

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 20 20" {...props}>
      <path d="M10 3.22l-.61-.6a5.5 5.5 0 00-7.78 7.77L10 18.78l8.39-8.4a5.5 5.5 0 00-7.78-7.77l-.61.61z" />
    </svg>
  )
}

export default SvgComponent
