import React from "react"
import YoutubeIcon from "../assets/youtube.js"
import TwitterIcon from "../assets/twitter.js"
import GithubIcon from "../assets/github.js"
import LinkedinIcon from "../assets/linkedin.js"
import FacebookIcon from "../assets/facebook.js"
import InstagramIcon from "../assets/instagram.js"
import DribbbleIcon from "../assets/dribbble.js"
import AngelIcon from "../assets/angellist.js"

const SocialIcon = ({ type }) => {
  switch (type) {
    case "linkedin":
      return <LinkedinIcon className="footer-social-icon" />
    case "twitter":
      return <TwitterIcon className="footer-social-icon" />
    case "facebook":
      return <FacebookIcon className="footer-social-icon" />
    case "youtube":
      return <YoutubeIcon className="footer-social-icon" />
    case "instagram":
      return <InstagramIcon className="footer-social-icon" />
    case "github":
      return <GithubIcon className="footer-social-icon" />
    case "dribbble":
      return <DribbbleIcon className="footer-social-icon" />
    case "angellist":
      return <AngelIcon className="footer-social-icon" />
    default:
      return null
  }
}

export default SocialIcon
