import React from "react"

const Experience = ({ data }) => (
  <section>
    <h1 className="section-header">Experience</h1>
    {data &&
      data.map((item, i) => (
        <article className="my-5" key={`${item.company}-${i}`}>
          <h2 className="text-2xl font-bold text-neutral-900">
            {item.role}
            <span className="text-lg text-neutral-700"> | {item.company}</span>
          </h2>
          <h3 className="text-lg text-neutral-700">
            {item.start} - {item.end || "PRESENT"}
          </h3>
          {/* <p className="py-6">{item.description}</p> */}
          {item.keyResponsabilities && (
            <>
              <h4 className="text-neutral-700">
                Key Qualifications and responsibilities
              </h4>
              <ul className="list-disc list-inside">
                {item.keyResponsabilities.map(responsability => (
                  <li className="text-sm">{responsability}</li>
                ))}
              </ul>
            </>
          )}
          {item.keyAchievements && (
            <>
              <h4 className="text-neutral-700 mt-2">Key achievements</h4>
              <div>
                {item.keyAchievements.map((achievement, index) => (
                  <div
                    key={index}
                    className="border-l-2 border-neutral-300 pl-2 mb-2"
                  >
                    <p className="text-sm">{achievement.description}</p>
                    {achievement.technos &&
                      achievement.technos.map(techno => (
                        <span key={techno} className="tag-sm">
                          {techno}
                        </span>
                      ))}
                  </div>
                ))}
              </div>
            </>
          )}
        </article>
      ))}
  </section>
)

export default Experience
